
import auth from './auth.json';
import accountSettings from './accountSettings.json';
import menu from './menu.json';
import general from './general.json';
import products from './products.json';
import system from './system.json';
import transactions from './transactions.json';
import voucher from './voucher.json';
import dashboard from './dashboard.json';
import announcements from './announcements.json';
import banners from './banners.json';
import webhooks from './webhooks.json';

export default {
  auth,
  accountSettings,
  menu,
  general,
  products,
  system,
  voucher,
  transactions,
  dashboard,
  announcements,
  webhooks,
  banners,
};
