/* eslint-disable import/prefer-default-export */
import { createAction } from 'redux-actions';

// Action Declarations
// Suggested Action Name Standard: ORG_MODULE_ACTION_ANY
export const getVehicleTypeAction = createAction('PIEZA_ADMIN_GET_VEHICLE_TYPE');
export const getVehicleTypeListAction = createAction('PIEZA_ADMIN_GET_VEHICLE_TYPE_LIST');
export const showVehicleTypeAction = createAction('PIEZA_ADMIN_SHOW_VEHICLE_TYPE');
export const createVehicleTypeAction = createAction('PIEZA_ADMIN_CREATE_VEHICLE_TYPE');
export const updateVehicleTypeAction = createAction('PIEZA_ADMIN_UPDATE_VEHICLE_TYPE');
export const deleteVehicleTypeAction = createAction('PIEZA_ADMIN_DELETE_VEHICLE_TYPE');
// models
export const showVehicleModelAction = createAction('PIEZA_ADMIN_SHOW_VEHICLE_MODEL');
export const updateVehicleModelAction = createAction('PIEZA_ADMIN_UPDATE_VEHICLE_MODEL');
export const deleteVehicleModelAction = createAction('PIEZA_ADMIN_DELETE_VEHICLE_MODEL');
// makes
export const getVehicleMakelAction = createAction('PIEZA_ADMIN_GET_VEHICLE_MAKE');
