import { handleActions } from 'redux-actions';

import {
  getWebhookAction,
  deleteWebhookAction,
  createWebhookAction,
  updateWebhookAction,
} from './WebhookActionConfig';

// reducers
const defaultState = {
  webhooks: {
    data: [],
    meta: {
      total: 0,
    },
  },
};

const reducer = handleActions({
  [getWebhookAction](state, { payload }) {
    return {
      ...state,
      webhooks: payload,
    };
  },
  [createWebhookAction](state, { payload }) {
    return {
      ...state,
      webhooks: {
        data: [
          { ...payload },
          ...state.webhooks.data,
        ],
        meta: {
          ...state.webhooks.meta,
          total: state.webhooks.meta.total + 1,
        },
      },
    };
  },
  [updateWebhookAction](state, { payload }) {
    return {
      ...state,
      webhooks: {
        ...state.webhooks,
        data: state.webhooks.data
          .map((webhook) => {
            if (webhook.id === payload.id) {
              return {
                ...webhook,
                ...payload,
              };
            }

            return webhook;
          }),
      },
    };
  },
  [deleteWebhookAction](state, { params }) {
    return {
      ...state,
      webhooks: {
        ...state.webhooks,
        data: state.webhooks.data
          .filter(webhook => webhook.id !== params.webhookId),
        meta: {
          ...state.webhooks.meta,
          total: state.webhooks.meta.total - 1,
        },
      },
    };
  },
}, defaultState);

export default reducer;
