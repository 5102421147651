/* eslint-disable import/prefer-default-export */
import { createAction } from 'redux-actions';

// Action Declarations
// Suggested Action Name Standard: ORG_MODULE_ACTION_ANY
export const getWebhookAction = createAction('PIEZA_ADMIN_GET_WEBHOOK');
export const showWebhookAction = createAction('PIEZA_ADMIN_SHOW_WEBHOOK');
export const createWebhookAction = createAction('PIEZA_ADMIN_CREATE_WEBHOOK');
export const updateWebhookAction = createAction('PIEZA_ADMIN_UPDATE_WEBHOOK');
export const deleteWebhookAction = createAction('PIEZA_ADMIN_DELETE_WEBHOOK');
